/**
 * Component to render the site header (responsive for mobile vs. desktop).
 */

import styled from "@emotion/styled";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactNode, useState } from "react";
import WordmarkPurple from "../images/01_Logo/Wordmark/SVG/Spring_Wordmark_Purple.svg";
import WordmarkWhite from "../images/01_Logo/Wordmark/SVG/Spring_Wordmark_White.svg";
import Close from "../images/Other/SVG/Close.svg";
import { PURPLE, WHITE } from "../utils/colors";
import { FullContainer, ResponsiveSpacing } from "./layout";
import { BodyLgWhite, BodySmlWhite, Mono } from "./typography";

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const WordmarkContainer = styled.div`
  width: 176px;
  height: 33px;
`;

const MobileFooterText = styled.span`
  font-size: 16px;
  line-height: 27px;
  color: ${WHITE};
  opacity: 0.5;
`;

const MobileFooterHeader = styled.span`
  font-size: 16px;
  text-transform: uppercase;
  color: ${WHITE};
  line-height: 27px;
`;

const MobileCopyright = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: ${WHITE};
  opacity: 0.5;
`;

const Hamburger = ({
  color,
  onClick,
}: {
  color: string;
  onClick: () => void;
}) => (
  <div
    onClick={onClick}
    className="flex lg:hidden flex-col justify-between items-stretch"
    style={{
      width: 18 + 5 + 5,
      height: 15 + 5 + 5,
      padding: 5,
      marginRight: -5,
      cursor: "pointer",
    }}
  >
    <div style={{ backgroundColor: color, height: 2 }} />
    <div style={{ backgroundColor: color, height: 2 }} />
    <div style={{ backgroundColor: color, height: 2 }} />
  </div>
);

const Menu = ({ children }: { children: ReactNode }) => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 10,
    }}
  >
    {children}
  </div>
);

const MenuBackground = () => (
  <div
    style={{
      position: "fixed",
      left: 0,
      right: 0,
      // Top and bottom extend to appear behind the built-in browser header and
      // footer (e.g., on mobile Safari).
      top: -100,
      bottom: -100,
      zIndex: 9,
      backgroundColor: PURPLE,
    }}
  />
);

export default function Header({
  color,
  context,
}: {
  color: string;
  context?: "blog-post";
}) {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  // TODO(charlie): Unify the blog and rest-of-site layouts aligned, then remove.
  const Container =
    context === "blog-post"
      ? ({ children }: { children: ReactNode }) => (
          <div className={"blog-content-width"}>{children}</div>
        )
      : FullContainer;
  return (
    <Absolute style={{ zIndex: 1000 }}>
      <Container>
        <ResponsiveSpacing xs={{ paddingTop: 28, paddingBottom: 28 }}>
          <div className={"flex flex-row items-center"}>
            <div className={"w-full lg:w-1/2"}>
              <div
                className={
                  "flex flex-row flex-grow items-center justify-between"
                }
              >
                <Link href="/">
                  <a>
                    <WordmarkContainer style={{ color }}>
                      <WordmarkPurple />
                    </WordmarkContainer>
                  </a>
                </Link>
                <Hamburger
                  color={color}
                  onClick={() => {
                    document.body.className = "noScroll";
                    setMenuOpen(!menuOpen);
                  }}
                />
              </div>
            </div>

            <div className={"w-0 lg:w-1/2"}>
              <div className="w-full lg:flex hidden flex-row justify-end flex-grow">
                <Link href="/technology">
                  <a
                    style={{
                      color,
                      textDecoration:
                        router.asPath === "/technology"
                          ? "underline"
                          : undefined,
                      paddingRight: 24,
                    }}
                  >
                    <Mono>Tech</Mono>
                  </a>
                </Link>
                <Link href="/science">
                  <a
                    style={{
                      color,
                      textDecoration:
                        router.asPath === "/science" ? "underline" : undefined,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                  >
                    <Mono>Science</Mono>
                  </a>
                </Link>
                <Link href="/request?p=1">
                  <a
                    style={{
                      color,
                      textDecoration:
                        router.asPath === "/request?p=1"
                          ? "underline"
                          : undefined,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                  >
                    <Mono>Partner</Mono>
                  </a>
                </Link>
                <Link href="/team">
                  <a
                    style={{
                      color,
                      textDecoration:
                        router.asPath === "/team" ? "underline" : undefined,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                    className="lg:hidden xl:block"
                  >
                    <Mono>Team</Mono>
                  </a>
                </Link>
                <Link href="/blog">
                  <a
                    style={{
                      color,
                      textDecoration:
                        router.asPath === "/blog" ? "underline" : undefined,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                  >
                    <Mono>Blog</Mono>
                  </a>
                </Link>
                <Link href="/news">
                  <a
                    style={{
                      color,
                      textDecoration:
                        router.asPath === "/news" ? "underline" : undefined,
                      paddingLeft: 24,
                    }}
                    className="lg:hidden xl:block"
                  >
                    <Mono>News</Mono>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </ResponsiveSpacing>
      </Container>

      {menuOpen ? (
        <>
          <MenuBackground />
          <Menu>
            <Absolute style={{ height: "100%" }}>
              <FullContainer style={{ height: "100%" }}>
                <div className="flex flex-col" style={{ height: "100%" }}>
                  <div
                    className="flex flex-row justify-between items-center"
                    style={{ marginTop: 32 }}
                  >
                    <Link href="/">
                      <WordmarkContainer>
                        <WordmarkWhite />
                      </WordmarkContainer>
                    </Link>

                    <Close
                      onClick={() => {
                        document.body.className = "";
                        setMenuOpen(false);
                      }}
                    />
                  </div>

                  <BodySmlWhite style={{ paddingTop: 48, paddingBottom: 28 }}>
                    Giving scientists superpowers.
                  </BodySmlWhite>

                  <BodyLgWhite style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Link href={"/technology"}>
                      <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        onClick={() => {
                          document.body.className = "";
                          setMenuOpen(false);
                        }}
                      >
                        Technology
                      </a>
                    </Link>
                  </BodyLgWhite>
                  <BodyLgWhite style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Link href={"/science"}>
                      <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        onClick={() => {
                          document.body.className = "";
                          setMenuOpen(false);
                        }}
                      >
                        Science
                      </a>
                    </Link>
                  </BodyLgWhite>
                  <BodyLgWhite style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Link href={"/request?p=1"}>
                      <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        onClick={() => {
                          document.body.className = "";
                          setMenuOpen(false);
                        }}
                      >
                        Partner
                      </a>
                    </Link>
                  </BodyLgWhite>
                  <BodyLgWhite style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Link href={"/team"}>
                      <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        onClick={() => {
                          document.body.className = "";
                          setMenuOpen(false);
                        }}
                      >
                        Team
                      </a>
                    </Link>
                  </BodyLgWhite>
                  <BodyLgWhite style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Link href={"/news"}>
                      <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        onClick={() => {
                          document.body.className = "";
                          setMenuOpen(false);
                        }}
                      >
                        News
                      </a>
                    </Link>
                  </BodyLgWhite>
                  <BodyLgWhite style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Link href={"/blog"}>
                      <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        onClick={() => {
                          document.body.className = "";
                          setMenuOpen(false);
                        }}
                      >
                        Blog
                      </a>
                    </Link>
                  </BodyLgWhite>

                  <div
                    className="flex flex-col flex-grow justify-end"
                    style={{ paddingTop: 28, paddingBottom: 28 }}
                  >
                    <div className="flex flex-col">
                      <MobileFooterHeader>Contact</MobileFooterHeader>
                      <MobileFooterText>
                        <Link href={"mailto:hello@springdisc.com"}>
                          <a style={{ color: "inherit" }}>General</a>
                        </Link>
                      </MobileFooterText>
                      <MobileFooterText>
                        <Link href={"mailto:partners@springdisc.com"}>
                          <a style={{ color: "inherit" }}>Partners</a>
                        </Link>
                      </MobileFooterText>
                      <MobileFooterText>
                        <Link href={"mailto:press@springdisc.com"}>
                          <a style={{ color: "inherit" }}>Press</a>
                        </Link>
                      </MobileFooterText>
                    </div>
                  </div>

                  <div
                    className="flex flex-col justify-end"
                    style={{ paddingBottom: 28 }}
                  >
                    <MobileCopyright>
                      © 2022 Spring Discovery. All rights reserved.
                    </MobileCopyright>
                  </div>
                </div>
              </FullContainer>
            </Absolute>
          </Menu>
        </>
      ) : null}
    </Absolute>
  );
}
