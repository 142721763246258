// Bootstrap cutoffs.
// That is, the screen-size cutoffs used by Bootstrap (and thus implicitly
// used in our layout via our use of Bootstrap).
export const SM = 576;
export const MD = 768;
export const LG = 992;
export const XL = 1200;

// Maximum width of site content.
// Up to this size, certain site content will remain flush to the edges of the
// screen. However, beyond this cutoff, content will snap to a smaller width
// (MAX_CONTENT_WIDTH), with only background colors extending.
// TODO(charlie): Unify with the blog (which uses 1480px, and no two-tier system).
export const MAX_FULL_SCREEN_WIDTH = 1800;
export const MAX_CONTENT_WIDTH = 1480;

// Inner and outer gutters.
// TODO(charlie): Unify with the blog (which uses 3.333333vw).
export const OUTER_GUTTER = 40;
export const INNER_GUTTER = 20;
