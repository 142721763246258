/**
 * Core layout components.
 */

import styled from "@emotion/styled";
import {
  INNER_GUTTER,
  LG,
  MAX_CONTENT_WIDTH,
  MAX_FULL_SCREEN_WIDTH,
  MD,
  OUTER_GUTTER,
  SM,
  XL,
} from "./constants";

/**
 * Render padding based on screen size.
 */
export const ResponsiveSpacing = styled.div(
  (props: {
    xs?: {
      paddingTop?: number;
      paddingLeft?: number;
      paddingBottom?: number;
      paddingRight?: number;
    };
    sm?: {
      paddingTop?: number;
      paddingLeft?: number;
      paddingBottom?: number;
      paddingRight?: number;
    };
    md?: {
      paddingTop?: number;
      paddingLeft?: number;
      paddingBottom?: number;
      paddingRight?: number;
    };
    lg?: {
      paddingTop?: number;
      paddingLeft?: number;
      paddingBottom?: number;
      paddingRight?: number;
    };
    xl?: {
      paddingTop?: number;
      paddingLeft?: number;
      paddingBottom?: number;
      paddingRight?: number;
    };
  }) => `
  @media (min-width: ${SM}px) {
    ${
      (props.sm || {}).hasOwnProperty("paddingTop")
        ? `padding-top: ${props.sm!.paddingTop}px;`
        : ""
    }
    ${
      (props.sm || {}).hasOwnProperty("paddingRight")
        ? `padding-right: ${props.sm!.paddingRight}px;`
        : ""
    }
    ${
      (props.sm || {}).hasOwnProperty("paddingBottom")
        ? `padding-bottom: ${props.sm!.paddingBottom}px;`
        : ""
    }
    ${
      (props.sm || {}).hasOwnProperty("paddingLeft")
        ? `padding-left: ${props.sm!.paddingLeft}px;`
        : ""
    }
  }
  
  @media (min-width: ${MD}px) {
    ${
      (props.md || {}).hasOwnProperty("paddingTop")
        ? `padding-top: ${props.md!.paddingTop}px;`
        : ""
    }
    ${
      (props.md || {}).hasOwnProperty("paddingRight")
        ? `padding-right: ${props.md!.paddingRight}px;`
        : ""
    }
    ${
      (props.md || {}).hasOwnProperty("paddingBottom")
        ? `padding-bottom: ${props.md!.paddingBottom}px;`
        : ""
    }
    ${
      (props.md || {}).hasOwnProperty("paddingLeft")
        ? `padding-left: ${props.md!.paddingLeft}px;`
        : ""
    }
  }
  @media (min-width: ${LG}px) {
    ${
      (props.lg || {}).hasOwnProperty("paddingTop")
        ? `padding-top: ${props.lg!.paddingTop}px;`
        : ""
    }
    ${
      (props.lg || {}).hasOwnProperty("paddingRight")
        ? `padding-right: ${props.lg!.paddingRight}px;`
        : ""
    }
    ${
      (props.lg || {}).hasOwnProperty("paddingBottom")
        ? `padding-bottom: ${props.lg!.paddingBottom}px;`
        : ""
    }
    ${
      (props.lg || {}).hasOwnProperty("paddingLeft")
        ? `padding-left: ${props.lg!.paddingLeft}px;`
        : ""
    }
  }
  @media (min-width: ${XL}px) {
    ${
      (props.xl || {}).hasOwnProperty("paddingTop")
        ? `padding-top: ${props.xl!.paddingTop}px;`
        : ""
    }
    ${
      (props.xl || {}).hasOwnProperty("paddingRight")
        ? `padding-right: ${props.xl!.paddingRight}px;`
        : ""
    }
    ${
      (props.xl || {}).hasOwnProperty("paddingBottom")
        ? `padding-bottom: ${props.xl!.paddingBottom}px;`
        : ""
    }
    ${
      (props.xl || {}).hasOwnProperty("paddingLeft")
        ? `padding-left: ${props.xl!.paddingLeft}px;`
        : ""
    }
  }

  ${
    (props.xs || {}).hasOwnProperty("paddingTop")
      ? `padding-top: ${props.xs!.paddingTop}px;`
      : ""
  }
  ${
    (props.xs || {}).hasOwnProperty("paddingRight")
      ? `padding-right: ${props.xs!.paddingRight}px;`
      : ""
  }
  ${
    (props.xs || {}).hasOwnProperty("paddingBottom")
      ? `padding-bottom: ${props.xs!.paddingBottom}px;`
      : ""
  }
  ${
    (props.xs || {}).hasOwnProperty("paddingLeft")
      ? `padding-left: ${props.xs!.paddingLeft}px;`
      : ""
  }
`,
);

export const LeftHalfPageContent = styled.div`
  @media (min-width: ${SM}px) {
    // At this size, centered and full-screen.
    max-width: ${540}px;
  }
  @media (min-width: ${MD}px) {
    // At this size, inset on both sizes, and stuck to the right.
    max-width: unset;
    padding-left: ${OUTER_GUTTER}px;
    padding-right: ${INNER_GUTTER}px;
    margin-right: unset;
  }
  @media (min-width: ${MAX_FULL_SCREEN_WIDTH}px) {
    // At this size, stretch to half the screen. No gutters.
    max-width: ${MAX_CONTENT_WIDTH / 2}px;
    padding-left: unset;
  }

  width: 100%;
  height: 100%;

  padding-right: 15px;
  margin-right: auto;

  padding-left: 15px;
  margin-left: auto;
`;

export const RightHalfPageContent = styled.div`
  @media (min-width: ${SM}px) {
    // At this size, centered and full-screen.
    max-width: ${540}px;
  }
  @media (min-width: ${MD}px) {
    // At this size, inset on both sizes, and stuck to the left.
    max-width: unset;
    padding-right: ${OUTER_GUTTER}px;
    padding-left: ${INNER_GUTTER}px;
    margin-left: unset;
  }
  @media (min-width: ${MAX_FULL_SCREEN_WIDTH}px) {
    // At this size, stretch to half the screen. No gutters.
    max-width: ${MAX_CONTENT_WIDTH / 2}px;
    padding-right: unset;
  }

  width: 100%;
  height: 100%;

  padding-left: 15px;
  margin-left: auto;

  padding-right: 15px;
  margin-right: auto;
`;

export const LeftHalfPageContentNoGutter = styled.div`
  @media (min-width: ${MAX_FULL_SCREEN_WIDTH}px) {
    max-width: ${MAX_CONTENT_WIDTH / 2}px;
  }

  width: 100%;
  height: 100%;

  margin-left: auto;
`;

export const RightHalfPageContentNoGutter = styled.div`
  @media (min-width: ${MAX_FULL_SCREEN_WIDTH}px) {
    max-width: ${MAX_CONTENT_WIDTH / 2}px;
  }

  width: 100%;
  height: 100%;

  margin-right: auto;
`;

export const RightHalfPageContentWithRightGutter = styled.div`
  @media (min-width: ${SM}px) {
    // At this size, centered and full-screen.
    max-width: ${540}px;
  }
  @media (min-width: ${MD}px) {
    // At this size, inset on the right, and stuck to the left.
    max-width: unset;
    padding-right: ${OUTER_GUTTER}px;
    padding-left: unset;
    margin-left: unset;
  }
  @media (min-width: ${MAX_FULL_SCREEN_WIDTH}px) {
    // At this size, stretch to half the screen. No gutters.
    max-width: ${MAX_CONTENT_WIDTH / 2}px;
    padding-right: unset;
  }

  width: 100%;
  height: 100%;

  padding-left: 15px;
  margin-left: auto;

  padding-right: 15px;
  margin-right: auto;
`;

export const FullPageContent = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${MAX_FULL_SCREEN_WIDTH}px) {
    max-width: ${MAX_CONTENT_WIDTH}px;
  }
`;

export const FullContainer = styled.div`
  @media (min-width: ${SM}px) {
    // At this size, centered and full-screen.
    max-width: ${540}px;
  }
  @media (min-width: ${MD}px) {
    // At this size, inset on both sizes, and stuck to the right.
    max-width: unset;
    padding-left: ${OUTER_GUTTER}px;
    padding-right: ${OUTER_GUTTER}px;
  }
  @media (min-width: ${MAX_FULL_SCREEN_WIDTH}px) {
    max-width: ${MAX_CONTENT_WIDTH}px;
    padding-right: unset;
    padding-left: unset;
  }

  width: 100%;
  margin-right: auto;
  padding-left: 15px;
  margin-left: auto;
  padding-right: 15px;
`;
