/**
 * Component to render the animated footer.
 */

import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import AnimatedFooterPattern from "../images/02_Pattern/SVG/Animated_Footer_Pattern.svg";

const bigLineAnimation = keyframes`
  0%, 100% {
    stroke-dashoffset: 400;
  }
  30% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 0;
  }
  70% {
    stroke-dashoffset: 400;
  }
`;

const littleLineAnimation = keyframes`
  0%, 100% {
    stroke-dashoffset: 400;
  }
  3% {
    stroke-dashoffset: 400;
  }
  33% {
    stroke-dashoffset: 0;
  }
  45% {
    stroke-dashoffset: 0;
  }
  70% {
    stroke-dashoffset: 400;
  }
`;

const OuterContainer = styled.div`
  max-width: 100vw;
  overflow: hidden;
  text-align: center;
`;

const AnimatedInnerContainer = styled.div`
  width: 520px;
  height: 125px;
  margin-left: auto;
  margin-right: auto;

  svg {
    margin-left: auto;
    margin-right: auto;
  }

  & .bigLine,
  & .littleLine {
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
  }

  &.loaded .bigLine {
    animation: ${bigLineAnimation} 6s linear infinite;
  }
  &.loaded .littleLine {
    animation: ${littleLineAnimation} 6s linear infinite;
  }
`;

const InnerContainer = styled.div`
  width: 520px;
  height: 125px;
  margin-left: auto;
  margin-right: auto;

  svg {
    margin-left: auto;
    margin-right: auto;
  }
`;

export default function AnimatedFooter({ animate }: { animate: boolean }) {
  const [className, setClassName] = useState("");

  // Only added the 'loaded' class after the component mounts
  // so that the CSS animations for all SVG elements start
  // at exactly the same time (instead of starting 1-by-1
  // whenever they are inserted into the DOM).
  useEffect(() => setClassName("loaded"), []);

  return (
    <OuterContainer>
      {animate ? (
        <AnimatedInnerContainer className={className}>
          <AnimatedFooterPattern />
        </AnimatedInnerContainer>
      ) : (
        <InnerContainer className={className}>
          <AnimatedFooterPattern />
        </InnerContainer>
      )}
    </OuterContainer>
  );
}
