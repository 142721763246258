/**
 * Component to add all necessary SEO tags to page header.
 */

import Head from "next/head";
import config from "../config";

export default function SEO({
  description,
  image,
  title,
}: {
  description?: string;
  image?: string;
  title?: string;
}) {
  const metaDescription = description || config.description;
  const metaImage = image || config.image;
  const metaTitle = title || config.title;
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
      />
      <meta name="theme-color" content="#6210FF" />
      <title>
        {metaTitle === config.title
          ? metaTitle
          : `${metaTitle} | ${config.title}`}
      </title>
      <meta name={`description`} content={metaDescription} />
      <meta property={`og:site_name`} content={config.title} />
      <meta property={`og:title`} content={metaTitle} />
      <meta property={`og:description`} content={metaDescription} />
      <meta property={`og:image`} content={`${config.url}${metaImage}`} />
      <meta property={`image`} content={`${config.url}${metaImage}`} />
      <meta property={`og:image:width`} content={`1200`} />
      <meta property={`og:image:height`} content={`630`} />
      <meta property={`og:type`} content={`website`} />
      <meta name={`twitter:card`} content={`summary_large_image`} />
      <meta name={`twitter:creator`} content={config.author} />
      <meta name={`twitter:title`} content={metaTitle} />
      <meta name={`twitter:description`} content={metaDescription} />
      <meta name={`twitter:image`} content={`${config.url}${metaImage}`} />
      <meta name={`twitter:image:width`} content={`1200`} />
      <meta name={`twitter:image:height`} content={`630`} />
    </Head>
  );
}
