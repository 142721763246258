/**
 * Typography styles.
 */

import styled from "@emotion/styled";
import { GREY, WHITE } from "../utils/colors";
import { LG, MD, SM, XL } from "./constants";

export const H1 = styled.span`
  color: black;

  font-size: 39px;
  line-height: 1.2;

  @media (min-width: ${940}px) {
    font-size: 48px;
    line-height: 1.17;
  }

  @media (min-width: ${1070}px) {
    font-size: 56px;
    line-height: 1.17;
  }

  @media (min-width: ${XL}px) {
    font-size: 64px;
    line-height: 1.17;
  }
`;

export const H2 = styled.div`
  color: ${WHITE};

  font-size: 32px;
  line-height: 38px;

  @media (min-width: ${MD}px) {
    font-size: 42px;
    line-height: 50px;
  }
`;

export const H3 = styled.span`
  color: ${WHITE};

  font-size: 20px;
  line-height: 24px;

  @media (min-width: ${LG}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Superheader = styled.span`
  font-family: "ApercuMonoPro", "Courier", "monospace";
  font-size: 16px;
  text-transform: uppercase;
  color: ${WHITE};
  letter-spacing: 1px;
  line-height: 19px;
`;

export const Caption = styled.span`
  font-family: "ApercuMonoPro", "Courier", "monospace";
  text-transform: uppercase;
  color: ${WHITE};

  font-size: 12px;
  line-height: 19px;

  @media (min-width: ${SM}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Clickable = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const Body = styled.div`
  font-size: 18px;
  color: ${WHITE};
  line-height: 27px;
`;

export const BodySmlGray = styled.span`
  font-size: 16px;
  color: ${GREY};
  line-height: 25px;
`;

export const BodySmlWhite = styled.span`
  font-size: 16px;
  color: ${WHITE};
  line-height: 25px;
`;

export const BodyLgWhite = styled.span`
  font-size: 36px;
  color: ${WHITE};
`;

export const Mono = styled.div`
  font-family: "ApercuMonoPro", "Courier", "monospace";
  font-size: 16px;
  text-transform: uppercase;
`;

export const FeedPageHeader = styled.h1`
  color: black;
  margin-bottom: 32px;
  display: block;
  font-size: 32px;
  font-family: "ApercuBoldPro", system-ui, Helvetica, sans-serif;
  font-weight: bold;
`;
