/**
 * Spring color palette.
 */
export const PURPLE = "#6210FF";
export const MAGENTA = "#AA00FF";
export const CORAL = "#FFA89A";
export const YELLOW = "#FFD054";

export const WHITE = "#FFFFFF";
export const GREY = "#949494";
