/**
 * Component to render the site footer (responsive for mobile vs. desktop).
 */
import styled from "@emotion/styled";
import Link from "next/link";

import WordmarkPurple from "../images/01_Logo/Wordmark/SVG/Spring_Wordmark_Purple.svg";
import { GREY, PURPLE, WHITE } from "../utils/colors";
import AnimatedFooter from "./AnimatedFooter";
import { FullContainer, ResponsiveSpacing } from "./layout";
import { BodySmlGray } from "./typography";

const NavigationFooter = styled.span`
  font-size: 16px;
  text-transform: uppercase;
  color: ${PURPLE};
  line-height: 25px;
`;

const WordmarkContainer = styled.div`
  width: 103px;
  height: 19px;
`;

const Copyright = styled.span`
  font-size: 14px;
  color: ${GREY};
  line-height: 25px;
  white-space: nowrap;
`;

export default function Footer({
  animate,
  invert,
  hideAboutUs,
}: {
  animate: boolean;
  invert: boolean;
  hideAboutUs?: boolean;
}) {
  return (
    <FullContainer>
      {!hideAboutUs && (
        <ResponsiveSpacing xs={{ paddingTop: 44 }} md={{ paddingTop: 72 }}>
          <WordmarkContainer
            className={"block md:hidden"}
            style={{ color: invert ? WHITE : PURPLE }}
          >
            <WordmarkPurple />
          </WordmarkContainer>
          <div className={"flex flex-wrap flex-row"}>
            <div className={"w-6/12 md:w-3/12"}>
              <ResponsiveSpacing xs={{ paddingTop: 38 }} md={{ paddingTop: 0 }}>
                <div className="flex flex-col justify-end">
                  <NavigationFooter
                    style={{ marginBottom: 14, color: invert ? WHITE : PURPLE }}
                  >
                    About
                  </NavigationFooter>
                  <BodySmlGray>
                    <Link href={"/team"}>
                      <a style={{ color: "inherit" }}>Team</a>
                    </Link>
                  </BodySmlGray>
                  <BodySmlGray>
                    <Link href={"/technology"}>
                      <a style={{ color: "inherit" }}>Technology</a>
                    </Link>
                  </BodySmlGray>
                  <BodySmlGray>
                    <Link href={"/news"}>
                      <a style={{ color: "inherit" }}>News</a>
                    </Link>
                  </BodySmlGray>
                </div>
              </ResponsiveSpacing>
            </div>
            <div className={"w-6/12 md:w-3/12"}>
              <ResponsiveSpacing
                xs={{ paddingTop: 38 }}
                md={{ paddingTop: 0 }}
                id={"contact"}
              >
                <div className="flex flex-col justify-end">
                  <NavigationFooter
                    style={{ marginBottom: 14, color: invert ? WHITE : PURPLE }}
                  >
                    Contact
                  </NavigationFooter>
                  <BodySmlGray>
                    <Link href={"mailto:hello@springdisc.com"}>
                      <a style={{ color: "inherit" }}>General</a>
                    </Link>
                  </BodySmlGray>
                  <BodySmlGray>
                    <Link href={"https://boards.greenhouse.io/springdiscovery"}>
                      <a style={{ color: "inherit" }}>Careers</a>
                    </Link>
                  </BodySmlGray>
                  <BodySmlGray>
                    <Link href={"mailto:press@springdisc.com"}>
                      <a style={{ color: "inherit" }}>Press</a>
                    </Link>
                  </BodySmlGray>
                </div>
              </ResponsiveSpacing>
            </div>
            <div className={"w-full md:w-6/12"}>
              <ResponsiveSpacing xs={{ paddingTop: 38 }} md={{ paddingTop: 0 }}>
                <div className="flex flex-col justify-end">
                  <NavigationFooter
                    style={{ marginBottom: 14, color: invert ? WHITE : PURPLE }}
                  >
                    Partnerships
                  </NavigationFooter>
                  <BodySmlGray>
                    Spring's tech is used by a range of partners across biotech,
                    pharma, and academic research. We provide both strategic
                    collaborations and software licensing to those looking for
                    novel biological insights or tools to accelerate their
                    science.{" "}
                    <Link href={"/request?p=1"}>
                      <a style={{ color: invert ? WHITE : PURPLE }}>
                        Contact us
                      </a>
                    </Link>
                    .
                  </BodySmlGray>
                </div>
              </ResponsiveSpacing>
            </div>
          </div>
        </ResponsiveSpacing>
      )}
      <div className="flex lg:hidden">
        <div
          className="flex flex-col items-center max-w-full"
          style={{ paddingTop: 34, color: invert ? WHITE : PURPLE }}
        >
          <Copyright>© 2023 Spring Discovery. All rights reserved.</Copyright>
          <AnimatedFooter animate={animate} />
        </div>
      </div>
      <div className="hidden lg:flex">
        <div
          className="flex flex-row flex-grow items-center"
          style={{ paddingTop: 50, color: invert ? WHITE : PURPLE }}
        >
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <WordmarkContainer style={{ color: invert ? WHITE : PURPLE }}>
              <WordmarkPurple />
            </WordmarkContainer>
          </div>
          <AnimatedFooter animate={animate} />
          <div
            style={{ flexGrow: 1, flexBasis: 0 }}
            className="flex flex-col items-end justify-center"
          >
            <Copyright>© 2022 Spring Discovery.</Copyright>
            <Copyright>All rights reserved.</Copyright>
          </div>
        </div>
      </div>
    </FullContainer>
  );
}
