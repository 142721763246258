/**
 * Wrapper component to render a page.
 */

import { CSSProperties, ReactNode } from "react";

export default function Page({
  children,
  style,
}: {
  children: ReactNode;
  style?: CSSProperties;
}) {
  return <div style={style}>{children}</div>;
}
